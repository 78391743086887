import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const ListenerAnalyticsDetailsTable = ({ listenersDetailList }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Patient ID",
                accessor: "patient_id",
            },
            {
                Header: "Patient Name",
                accessor: "pname",
            },
            {
                Header: "Reason",
                accessor: "sessionTopic",
            },
            {
                Header: "Started Time",
                accessor: "started",
            },
            {
                Header: "Duration (In Mins)",
                accessor: "durationInMin",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Chat URL",
                accessor: "chat_url",
            },
            {
                Header: "Audio URL",
                accessor: "audio_url",
            },
            {
                Header: "Video URL",
                accessor: "video_url",
            },
        ],
        []
    )

    var tableRender = (
        <Table
            columns={columns}
            data={listenersDetailList}
        />
    )

    return <TableStyles>{listenersDetailList ? tableRender : " "} </TableStyles>
}