import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { Layout } from "../../components/Layout"
import { BallBeat } from "react-pure-loaders"
import { fetchListenersDetailList } from "../../state/analytics/analyticsActions"
import { AnalyticsSection } from "../../style/analytics"
import { ListenerAnalyticsDetailsTable } from "../../components/ListenerAnalyticsDetailsTable"
import { AnalyticsTab } from "./analyticsTab";

function listenerAnalytics({
    analyticsData,
    analyticsDetailList,
    fetchListenersDetailList,
    location = {},
    selectedTab,
}) {

    if (location.state === null || undefined) {
        navigate("/")
    }

    let listenerId = location.state ? location.state.data.listener_id : " "

    useEffect(() => {
        fetchListenersDetailList(listenerId)
    }, [fetchListenersDetailList])

    useEffect(() => {
        if (selectedTab !== "LISTENERS") {
            navigate("/analytics");
        }
    }, [selectedTab])

    return (
        <div>
            <Layout>
                <AnalyticsSection>
                    <AnalyticsTab activeTab="LISTENERS" />
                    <br />
                    <br />
                    <h4>
                        Session details of Listener ID -{" "}
                        <span style={{ color: "#4299E1" }}> {location.state ? location.state.data.listener_id : " "}</span>
                        &nbsp; with Listener Name -{" "}
                        <span style={{ color: "#4299E1" }}> {location.state ? location.state.data.listener_name : " "} </span>
                    </h4>
                    {/* <h4>
                        Listener Name -{" "}
                        <span > {location.state ? location.state.data.listener_name : " "} </span>
                    </h4> */}
                    {analyticsData.loading ?
                        (
                            <>
                                <br />
                                <br />
                                <h4>
                                    Loading Listener Analytics Details{" "}
                                    <span>
                                        <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                    </span>
                                </h4>
                            </>
                        ) :
                        analyticsData.error ?
                            (
                                <>
                                    <br />
                                    <h2> Listener  Analytics</h2>
                                    <br />
                                    <h4> ERROR ⚠️ FETCHING LISTENER ANALYTICS DETAILS</h4>
                                    <br />
                                    <p style={{ color: "grey" }}>{analyticsData.error}</p>
                                    <br />
                                    <br />
                                </>
                            ) : analyticsDetailList.length === 0 ?
                                (
                                    <>
                                        <br />
                                        <br />
                                        <h4> No session history available for the listener</h4>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                ) :
                                <>
                                    <br />
                                    <ListenerAnalyticsDetailsTable listenersDetailList={analyticsDetailList} />
                                </>
                    }
                </AnalyticsSection>
            </Layout>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        analyticsData: state.analytics,
        analyticsDetailList: state.analytics.listenerDetails,
        selectedTab: state.analytics.selectedTab,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListenersDetailList: (listenerId) => dispatch(fetchListenersDetailList(listenerId)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(listenerAnalytics)
